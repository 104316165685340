import { useQuery } from '@tanstack/react-query';

import { getTemplateRoles } from '../components/Admin/data-access/getTemplateRoles';

export const useRoleTemplates = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['templateRoles'],
    queryFn: getTemplateRoles,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const list = data ?? [];

  return {
    isLoading,
    data: {
      list,
      map: new Map(list.map((t) => [t.id, t])),
    },
  };
};
