import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import TableBase from './TableBase';

import { postFloor } from '../../../utility';
import { getBuildings } from '../data-access/getBuildings';
import { getFloors } from '../data-access/getFloors';

import type { OnSubmitFormModal } from '../modals/FormModalBase';
import type { DataFields } from '../types/DataFields';

export type FieldTypes = {
  buildingId: string;
  floorNumber: string;
};

const fields: DataFields<FieldTypes> = [
  {
    field: 'buildingId',
    label: 'Building',
    width: 200,
    editType: 'special',
    editable: false,
    valueGetter: (params) =>
      params.row.buildingDisplayName || params.row.buildingDomainId,
    schema: yup.string().required('Building is required'),
  },
  {
    field: 'floorNumber',
    label: 'Floor Number',
    width: 160,
    editType: 'text',
    editable: false,
    schema: yup
      .number()
      .positive()
      .integer()
      .required('Floor number is required'),
  },
];

const FloorsTable = () => {
  const queryClient = useQueryClient();

  const {
    isLoading: buildingsLoading,
    isError: buildingsError,
    data: buildings,
  } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  const {
    isLoading: floorsLoading,
    isError: floorsError,
    data: floors,
  } = useQuery({
    queryKey: ['floors'],
    queryFn: getFloors,
  });

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async ({ buildingId, floorNumber }) => {
      try {
        await postFloor({ buildingId, floorNumber: Number(floorNumber) });

        await queryClient.invalidateQueries({
          queryKey: ['floors'],
        });

        const building = buildings?.find((b) => b.id === buildingId);

        toast.success(
          `Successfully added floor ${floorNumber} to ${building?.displayName}`,
        );
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response?.data.error) {
            toast.error(error.response?.data.error);
            return;
          }
 
            toast.error(`Error adding floor: ${error}`);
            return;
          
        }

        toast.error(`Error adding floor${error ? `: ${error}` : ''}`);
      }
    },
    [buildings],
  );

  return (
    <TableBase<FieldTypes>
      itemName='Floor'
      fields={fields}
      data={floors}
      loading={floorsLoading}
      error={floorsError}
      modalLoading={buildingsLoading}
      modalError={buildingsError}
      onAddSubmit={handleAddSubmit}
      getRowId={({ buildingId, floorNumber }) => `${buildingId}-${floorNumber}`}
      disableEditing
    />
  );
};

export default FloorsTable;
