import { useMemo } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Divider, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { useWatch } from 'react-hook-form';

import type {
  CompoundSubjectAndAction,
  Permissions,
} from '@inspiren-monorepo/feature-permissions';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { FeatureAccordion } from './FeatureAccordion';
import RoleControlledCheckMarks from './RoleControlledCheckMarks';
import RoleControlledCheckbox from './RoleControlledCheckbox';
import SelectTrainingVideo from './SelectTrainingVideo';

import { useRoleTemplates } from '../../../../../hooks/useRoleTemplates';
import ControlledCheckbox from '../../../modals/ControlledCheckbox';
import ControlledDropdown from '../../../modals/ControlledDropdown';
import ControlledTextField from '../../../modals/ControlledTextField';
import FormCategory from '../../../modals/FormCategory';
import {
  adminPagePermissions,
  allPermissions,
  eventReviewPagePermissions,
  globalPermissions,
  mobilePermissions,
  roomsPagePermissions,
} from '../constants/permissions';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  type: 'add' | 'edit';
  setValue?: any;
  organization: AdminTypes.OrganizationDto;
}

export const RolesFormModal = ({
  control,
  type,
  setValue,
  organization,
}: Props) => {
  const virtualCareAccess = useWatch({
    control,
    name: 'virtual-care.access',
  });

  const roomsPageView = useWatch({
    control,
    name: 'virtual-care.rooms.view',
  });

  const eventReviewPageView = useWatch({
    control,
    name: 'virtual-care.event-review.view',
  });

  const adminPageView = useWatch({
    control,
    name: 'virtual-care.admin.view',
  });

  const mobileAccess = useWatch({
    control,
    name: 'mobile.access',
  });

  const fallRiskOffPermission = useWatch({
    control,
    name: 'global.fall-risk-off.view',
  });

  const trainingVideoUrlMobile = useWatch({
    control,
    name: 'trainingVideoUrlMobile',
  });

  const { data: templateRoles, isLoading: templateRolesIsLoading } =
    useRoleTemplates();

  const templateDisplayNames = useMemo(
    () =>
      templateRoles.list
        ?.map(
          (role: AdminTypes.RoleTemplate) =>
            role.displayName.charAt(0).toUpperCase() +
            role.displayName.slice(1),
        )
        .sort(),
    [templateRoles],
  );

  const onTemplateChange = (e: SelectChangeEvent) => {
    const selectedTemplate = templateRoles.list.find(
      (role: AdminTypes.RoleTemplate) => role.displayName === e.target.value,
    );

    const permissions = selectedTemplate?.permissions?.reduce(
      (acc, permission: Permissions) => {
        const permissionField = `${permission.subject}.${permission.action}`;
        return {
          ...acc,
          [permissionField]: true,
        };
      },
      {},
    ) as Record<CompoundSubjectAndAction, boolean> | undefined;

    allPermissions.forEach((permission) => {
      setValue(permission.id, permissions?.[permission.id] || false);
    });
  };

  const disableRequireTraining = useMemo(
    () =>
      isEmpty(trainingVideoUrlMobile) &&
      isEmpty(organization.defaultTrainingVideoUrlMobile),
    [trainingVideoUrlMobile, organization.defaultTrainingVideoUrlMobile],
  );

  return (
    <>
      <ControlledDropdown
        control={control}
        id='templateId'
        label='Template'
        items={templateDisplayNames || []}
        disabled={type === 'edit' || templateRolesIsLoading}
        onChange={onTemplateChange}
        required
      />
      <ControlledTextField
        control={control}
        id='displayName'
        label='Role Name'
      />
      <ControlledTextField
        control={control}
        id='maxAssign'
        label='Assignment Limit'
      />

      <Divider sx={{ my: 2 }} />

      <ControlledCheckbox
        control={control}
        id='requireTrainingVideoMobile'
        label='Require Training Video (Mobile)?'
        disabled={disableRequireTraining}
        tooltip={
          disableRequireTraining
            ? 'You cannot set the training video to required unless a video is set for either the org or the role'
            : undefined
        }
      />
      <SelectTrainingVideo
        control={control}
        id='trainingVideoUrlMobile'
        label='Training Video (Mobile)'
      />

      <Divider sx={{ my: 2 }} />

      <FormCategory title='Global Permissions'>
        <Grid container>
          {globalPermissions.map((permission) => (
            <Grid item xs={12} sm={6} key={permission.id}>
              <RoleControlledCheckMarks
                control={control}
                id={permission.id}
                label={permission.label}
                setValue={setValue}
                fallRiskOffPermission={fallRiskOffPermission}
              />
            </Grid>
          ))}
        </Grid>
      </FormCategory>

      <FormCategory title='Virtual Care Permissions'>
        <FeatureAccordion
          summaryComponent={
            <RoleControlledCheckbox
              control={control}
              id='virtual-care.access'
              label='Virtual Care Access'
              setValue={setValue}
            />
          }
          summaryState={virtualCareAccess}
          detailComponent={
            <Grid container>
              <Grid item xs={12} sm={6}>
                <RoleControlledCheckMarks
                  control={control}
                  id='virtual-care.tableau.view'
                  label='View Tableau Launch Button'
                  disabled={!virtualCareAccess}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RoleControlledCheckMarks
                  control={control}
                  id='virtual-care.analytics.view'
                  label='View Analytics Page'
                  disabled={!virtualCareAccess}
                  setValue={setValue}
                />
              </Grid>
            </Grid>
          }
        />
        <FeatureAccordion
          summaryComponent={
            <RoleControlledCheckbox
              control={control}
              id='virtual-care.rooms.view'
              label='Rooms Page'
              disabled={!virtualCareAccess}
              setValue={setValue}
            />
          }
          summaryState={roomsPageView}
          detailComponent={
            <Grid container>
              {roomsPagePermissions.map((permission) => (
                <Grid item xs={12} sm={6} key={permission.id}>
                  <RoleControlledCheckMarks
                    control={control}
                    id={permission.id}
                    label={permission.label}
                    disabled={!roomsPageView}
                    setValue={setValue}
                  />
                </Grid>
              ))}
            </Grid>
          }
        />
        <FeatureAccordion
          summaryComponent={
            <RoleControlledCheckbox
              control={control}
              id='virtual-care.event-review.view'
              label='Event Review Page'
              disabled={!virtualCareAccess}
              setValue={setValue}
            />
          }
          summaryState={eventReviewPageView}
          detailComponent={
            <Grid container>
              {eventReviewPagePermissions.map((permission) => (
                <Grid item xs={12} sm={6} key={permission.id}>
                  <RoleControlledCheckMarks
                    control={control}
                    id={permission.id}
                    label={permission.label}
                    setValue={setValue}
                    disabled={!eventReviewPageView}
                  />
                </Grid>
              ))}
            </Grid>
          }
        />
        <FeatureAccordion
          summaryComponent={
            <RoleControlledCheckbox
              control={control}
              id='virtual-care.admin.view'
              label='Admin Page'
              disabled={!virtualCareAccess}
              setValue={setValue}
            />
          }
          summaryState={adminPageView}
          detailComponent={
            <Grid container>
              {adminPagePermissions.map((permission) => (
                <Grid item xs={12} sm={6} key={permission.id}>
                  <RoleControlledCheckMarks
                    control={control}
                    id={permission.id}
                    label={permission.label}
                    disabled={!adminPageView}
                    setValue={setValue}
                  />
                </Grid>
              ))}
            </Grid>
          }
        />
      </FormCategory>

      <FormCategory title='Mobile Permissions'>
        <FeatureAccordion
          summaryComponent={
            <RoleControlledCheckbox
              control={control}
              id='mobile.access'
              label='Mobile Access'
              setValue={setValue}
            />
          }
          summaryState={mobileAccess}
          detailComponent={
            <Grid container>
              {mobilePermissions.map((permission) => (
                <Grid item xs={12} sm={6} key={permission.id}>
                  <RoleControlledCheckMarks
                    control={control}
                    id={permission.id}
                    label={permission.label}
                    disabled={!mobileAccess}
                    setValue={setValue}
                  />
                </Grid>
              ))}
            </Grid>
          }
        />
      </FormCategory>
    </>
  );
};
