import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';


export async function getUnitsByOrg(org: string) {
  return Api.get<AdminTypes.UnitDto[]>(`admin/v1/units/org/${org}`).then(
    (res) => res.data,
  );
}
