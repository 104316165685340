import { useCallback } from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import type { Permissions } from '@inspiren-monorepo/feature-permissions';

import { EditOrganizationSettingsModal } from './EditOrganizationSettingsModal';
import { OrgSettingsPanel } from './OrgSettingsPanel';
import { RolesFormModal } from './RolesFormModal';

import { useIsUmbrella } from '../../../../../HOC/CurrentUserContextProvider';
import { useIsAdmin } from '../../../../../hooks/useIsAdmin';
import { useRoleTemplates } from '../../../../../hooks/useRoleTemplates';
import Loading from '../../../../../screens/Loading';
import { Can } from '../../../../Can/Can';
import { ShowPageHeader } from '../../../components/ShowPageHeader';
import { postRole } from '../../../data-access/postRole';
import { putRole } from '../../../data-access/putRole';
import { useAdminOrg } from '../../../hooks/useAdminOrg';
import { useOrganizationRoles } from '../../../hooks/useOrganizationRoles';
import { useTrainingVideos } from '../../../hooks/useTrainingVideo';
import TableBase from '../../TableBase';
import { orgProfileFields } from '../constants/orgProfileFields';
import toDotNotation from '../helpers/toDotNotation';
import { transformFieldsToPermissions } from '../helpers/transformFieldsToPermissions';
import { transformPermissionsToFields } from '../helpers/transformPermissionsToFields';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../../modals/FormModalBase';
import type { RoleFieldTypes } from '../types/RoleFieldTypes';

export const OrgProfile = () => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const isUmbrella = useIsUmbrella();

  const { data: org, isLoading: orgsLoading } = useAdminOrg({ orgId: id });

  const {
    data: rolesData,
    isLoading: rolesLoading,
    isError: rolesError,
  } = useOrganizationRoles(id);

  const { isLoading: trainingVideosLoading } = useTrainingVideos();
  const { data: roleTemplates } = useRoleTemplates();

  const loading = orgsLoading || rolesLoading || trainingVideosLoading;

  const updatedRoles = transformPermissionsToFields(
    rolesData ?? [],
    roleTemplates.map,
  );

  const { isAdmin } = useIsAdmin();

  const disableEditing = orgsLoading || !org || !isAdmin || isUmbrella;

  const renderModal: RenderFormModal<RoleFieldTypes> = useCallback(
    ({ control, type, setValue }) => {
      if (!org) {
        return null;
      }

      return (
        <RolesFormModal
          control={control}
          type={type}
          setValue={setValue}
          organization={org}
        />
      );
    },
    [updatedRoles, id],
  );

  const handleAddSubmit: OnSubmitFormModal<RoleFieldTypes> = useCallback(
    async (submitData) => {
      try {
        const params = toDotNotation(submitData) as RoleFieldTypes;

        const filteredParams = Object.keys(params).reduce((acc, key) => {
          if (params[key as keyof RoleFieldTypes]) {
            return {
              ...acc,
              [key]: params[key as keyof RoleFieldTypes],
            };
          }

          return acc;
        }, {});

        const data = {
          templateId: params.templateId,
          displayName: params.displayName,
          maxAssign: params.maxAssign ? Number(params.maxAssign) : undefined,
          requireTrainingVideoMobile:
            params.requireTrainingVideoMobile || false,
          trainingVideoUrlMobile: params.trainingVideoUrlMobile || undefined,
        };

        const permissions = transformFieldsToPermissions(
          filteredParams as RoleFieldTypes,
        );

        await postRole({
          ...data,
          permissions: permissions as Permissions[],
          orgId: id!,
        });

        toast.success('Successfully added Role');

        await queryClient.invalidateQueries({
          queryKey: ['org', id, 'roles'],
        });
      } catch (error) {
        toast.error(`Error adding Role${error ? `: ${error}` : ''}`);
      }
    },
    [id],
  );

  const handleEditSubmit: OnSubmitFormModal<RoleFieldTypes> = useCallback(
    async (editdata) => {
      try {
        const params = toDotNotation(editdata) as RoleFieldTypes;

        const filteredParams = Object.keys(params).reduce((acc, key) => {
          if (params[key as keyof RoleFieldTypes]) {
            return {
              ...acc,
              [key]: params[key as keyof RoleFieldTypes],
            };
          }

          return acc;
        }, {});

        const data = {
          templateId: params.templateId,
          displayName: params.displayName,
          maxAssign: params.maxAssign ? Number(params.maxAssign) : undefined,
          requireTrainingVideoMobile:
            params.requireTrainingVideoMobile || false,
          trainingVideoUrlMobile: params.trainingVideoUrlMobile,
          id: params.id,
          orgId: params.orgId,
        };

        const permissions = transformFieldsToPermissions(
          filteredParams as RoleFieldTypes,
        );

        await putRole({
          ...data,
          permissions: permissions as Permissions[],
        });

        toast.success('Successfully updated role');

        await queryClient.invalidateQueries({
          queryKey: ['org', org?.id, 'roles'],
        });
      } catch (error) {
        toast.error(`Error updating organization${error ? `: ${error}` : ''}`);
      }
    },
    [id],
  );

  if (isNil(org)) {
    return <Loading />;
  }

  return (
    <Stack>
      <ShowPageHeader
        backPath='/admin/organizations'
        prefix='Organization'
        value={id}
        actions={
          <EditOrganizationSettingsModal
            organization={org}
            disabled={disableEditing}
          />
        }
        data-testid='org-profileHeader'
      />
      <OrgSettingsPanel
        org={org}
        loading={orgsLoading || rolesLoading}
        roles={rolesData}
      />
      <Can permission={{ action: 'view', subject: 'virtual-care.admin.roles' }}>
        <TableBase<RoleFieldTypes>
          itemName='Role'
          fields={orgProfileFields}
          data={updatedRoles}
          loading={loading}
          error={rolesError}
          renderModal={renderModal}
          modalLoading={orgsLoading}
          tableHeader={
            <Typography variant='h5' mx={1} pt={0.5}>
              Roles
            </Typography>
          }
          addExport={false}
          onAddSubmit={handleAddSubmit}
          onEditSubmit={handleEditSubmit}
          disableAddButton={!isAdmin}
          disableEditing={!isAdmin}
        />
      </Can>
    </Stack>
  );
};
